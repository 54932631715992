import { Clue } from './Clue'
import { Command } from './Command'

/* eslint-disable @typescript-eslint/no-empty-function */
export class Game {
  clientId: string
  roomId: string
  active = false
  complete = false
  clueText = ''
  clue: Clue | null
  clues: Array<string>
  startTime = 0
  endTime = 0
  commands: Array<Command>
  // startDate: Date
  // adjustedTime = 0
  // finished = false
  // clueLimit = 3
  // succeeded = false

  constructor (clientId: string, roomId: string) {
    this.clientId = clientId
    this.roomId = roomId
    this.clue = null
    this.clues = []
    this.commands = []
  }

  syncData (data: any): any {
    const keys = Object.getOwnPropertyNames(new Game('', ''))
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]]) {
        // TODO WARNING - don't do this (eval)
        // eslint-disable-next-line no-eval
        eval(`this.${keys[i]} = data[keys[i]]`)
      }
    }
  }

  start (): void {}
  end (): void {}
  // adjustTime (minutes: number): void {}

  toJSON (): any {
    const commands: Array<Command> = []
    this.commands.forEach(command => {
      commands.push(command.toJSON())
    })

    return {
      clientId: this.clientId,
      roomId: this.roomId,
      active: this.active,
      clueText: this.clueText,
      clue: this.clue ? this.clue.toJSON ? this.clue.toJSON() : this.clue : {},
      clues: this.clues,
      startTime: this.startTime,
      endTime: this.endTime,
      complete: this.complete,
      commands
    }
  }
}
