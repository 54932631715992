
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  props: ['name', 'id'],
  data () {
    return {}
  },
  methods: {
    toast () {
      this.$toast.add({ severity: 'info', summary: 'Hello ' + this.msg })
    }
  }
})
export default class RoomCard extends Vue {}
