import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Badge from 'primevue/badge'
import Button from 'primevue/button'
import Card from 'primevue/card'
import ColorPicker from 'primevue/colorpicker'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Knob from 'primevue/knob'
import Menubar from 'primevue/menubar'
import PrimeVue from 'primevue/config'
import ScrollPanel from 'primevue/scrollpanel'
import Sidebar from 'primevue/sidebar'
import Slider from 'primevue/slider'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'

import RoomsList from '@/components/rooms/RoomsList.vue'
import RoomCard from '@/components/rooms/RoomCard.vue'

import 'primevue/resources/themes/mdc-dark-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import './css/override.css'

const app = createApp(App)

app.use(store)
app.use(router)

app.directive('focus', {
  mounted (el) {
    // When the bound element is inserted into the DOM...
    el.focus() // Focus the element
  }
})

// Add PrimeVue components here
app.use(PrimeVue, { ripple: false })
app.use(ConfirmationService)
app.use(ToastService)

// PrimeVue Components
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Badge', Badge)
app.component('Button', Button)
app.component('Card', Card)
app.component('ConfirmPopup', ConfirmPopup)
app.component('ColorPicker', ColorPicker)
app.component('Dialog', Dialog)
app.component('InputText', InputText)
app.component('Knob', Knob)
app.component('Menubar', Menubar)
app.component('ScrollPanel', ScrollPanel)
app.component('Sidebar', Sidebar)
app.component('Slider', Slider)
app.component('Toast', Toast)

// Runner Components
app.component('RoomCard', RoomCard)
app.component('RoomsList', RoomsList)

app.mount('#app')

console.log(process.env)
