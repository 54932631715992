import { db } from '../firebase'

import {
  getAuth,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

class AuthenticationService {
  async login (email: string, password: string) {
    const auth = getAuth()

    auth.setPersistence(browserLocalPersistence)

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )

    return userCredential.user.email
  }

  logout () {
    const auth = getAuth()
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        console.log(error)
      })
  }
}

export default new AuthenticationService()
