
import { Options, Vue } from 'vue-class-component'

@Options({
  components: { },
  props: {
    rooms: Array
  },
  data () {
    return {
    }
  },
  methods: {
    toast () {
      this.$toast.add({ severity: 'info', summary: 'Hello ' + this.msg })
    }
  }
})

export default class RoomsList extends Vue {
  rooms: any;
}
