import { Puzzle } from './Puzzle'
import { Sound, SoundType } from './Sound'

// Any time an attribute is added to the Room class,
// be sure to update and add it to toJSON as well as the RoomService SyncRoom
export class Room {
  clientId: string
  backgroundImage = ''
  name: string
  timeLimit: number
  clueLimit = 5
  puzzles: Puzzle[]
  clues: Array<string>
  sounds: Array<Sound> = [new Sound('Alert', 'https://firebasestorage.googleapis.com/v0/b/escapeit-runner-dev.appspot.com/o/sounds%2Falert.mp3?alt=media&token=17184302-4e19-4d68-8033-d879769d4c22', SoundType.Alert)]
  timeRemainingDialog = {
    top: 0,
    left: 0,
    header: 'Time Remaining',
    color: 'ffffff',
    backgroundColor: 'ffffffff',
    backgroundOpacity: 1,
    contentSize: 0
  }

  clueTransmissionDialog = {
    top: 0,
    left: 0,
    header: 'Clue Transmission',
    color: 'ffffff',
    backgroundColor: 'ffffffff',
    backgroundOpacity: 1,
    contentSize: 0
  }

  clueIconDialog = {
    top: 0,
    left: 0,
    header: 'Clues Remaining',
    color: 'ffffff',
    backgroundColor: 'ffffffff',
    backgroundOpacity: 1,
    contentSize: 0
  }

  // constructor (clientId: string, name: string, puzzles: Puzzle[]) {
  //   this.clientId = clientId
  //   this.name = name
  //   this.puzzles = puzzles
  // }

  constructor (clientId: string, name: string, timeLimit: number) {
    this.clientId = clientId
    this.name = name
    this.puzzles = []
    this.timeLimit = timeLimit
    this.clues = []
  }

  setTimeLimit (timeLimit: number): void {
    this.timeLimit = timeLimit
  }

  setPuzzles (puzzles: Puzzle[]): void {
    this.puzzles = puzzles
  }

  addPuzzle (puzzle: Puzzle): void {
    if (this.puzzles == null) {
      this.puzzles = []
    }
    this.puzzles.push(puzzle)
  }

  removePuzzle (puzzleIndex: number): void {
    this.puzzles.splice(puzzleIndex, 1)
  }

  toJSON (): any {
    const puzzles: Array<Puzzle> = []

    this.puzzles.forEach((puzzle) => {
      puzzles.push(puzzle.toJSON())
    })

    const sounds: Array<Sound> = []

    this.sounds.forEach((sound) => {
      sounds.push(sound.toJSON())
    })

    return {
      clientId: this.clientId,
      name: this.name,
      timeLimit: this.timeLimit,
      puzzles,
      timeRemainingDialog: this.timeRemainingDialog,
      clueTransmissionDialog: this.clueTransmissionDialog,
      clueIconDialog: this.clueIconDialog,
      backgroundImage: this.backgroundImage,
      clues: this.clues,
      clueLimit: this.clueLimit,
      sounds
    }
  }
}
