export enum SoundType {
  Clue = 'Clue',
  Background = 'Background',
  Loop = 'Loop',
  Alert = 'Alert'
}

export class Sound {
  name: string
  url: string
  type: SoundType
  autoplay = false

  public constructor (name: string, url: string, type: SoundType, autoplay = false) {
    this.name = name
    this.url = url
    this.type = type
    this.autoplay = autoplay
  }

  toJSON (): any {
    return {
      name: this.name,
      url: this.url,
      type: this.type,
      autoplay: this.autoplay
    }
  }
}
