import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "",
  "data-test": "rooms-list"
}
const _hoisted_2 = { key: "idx" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (idx, room) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_2))
    }), 128))
  ]))
}