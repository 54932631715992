import { createStore } from 'vuex'

import AuthenticationService from '@/services/AuthenticationService'
import { Room } from '@/models/Room'
import { Game } from '@/models/Game'

export default createStore({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: { email: '' },
    editMode: false,
    room: new Room('', '', 60),
    game: new Game('', ''),
    navigation: [
      {
        label: 'Runner',
        to: '/',
        icon: 'pi pi-fw pi-home'
      }
    ]
  },
  getters: {
    isLoggedIn: (state) => state.user.email.length > 0,
    editMode (state) {
      return state.editMode
    },
    isGameActive (state) {
      return state.game.active
    },
    getUser (state) {
      return state.user
    },
    getNavigation (state) {
      const items = [...state.navigation]

      if (state.user.email) {
        items.push({
          label: 'Logout',
          to: '/logout',
          icon: ''
        })
      } else {
        items.push({
          label: 'Login',
          to: '/login',
          icon: ''
        })
      }
      return items
    }
  },
  mutations: {
    toggleEditMode (state) {
      state.editMode = !state.editMode
    },
    logout (state) {
      state.user.email = ''
    },
    setGame (state, game) {
      state.game = game
    },
    updateUser (state, email: string) {
      state.user.email = email
    }
  },
  actions: {
    logout ({ commit }) {
      AuthenticationService.logout()
      commit('logout')
    },
    updateUser ({ commit }, email: string) {
      commit('updateUser', email)
    }
  },
  modules: {}
})
